import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Reporting = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='звітність'>
			
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1h-Jt4ggAfsOyXkX2-8HB98KH2GGIC6n1/view?usp=drive_link" target="_blanck">
					Річний звіт 2022-2023н.р.про діяльність "ДПТНЗ ЛВПУКТБ" 
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13uApes6dGx9KKMmzTwG2nUoMXTMWBbhw/view?usp=drive_link" target="_blanck">
						Кошторис на 2024 рік
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/10n0XGYp2RxSMeoYLaMAle6bEss-7szVN/view?usp=drive_link" target="_blanck">
					Звіт про фінансові результати 2023
					</a>
				</h4>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1NIlz73ndQBYFN6Wd2WTZWrQlTqXaRtrK/view?usp=share_link" target="_blanck">
						Бюджет на 2023 рік
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/140b8sqfilTv9RNcSRp0vyZz27FwdY3aD/view" target="_blanck">
						Кошторис на 2023 рік
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1LgSnpfcKsswVDuZaEtcKzLDJCWiOyb1B/view?usp=sharing" target="_blanck">
						Річний звіт про діяльність ДПТНЗ ЛВПУКТБ за 2021-2022 н.р.
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/14gzbXPVrGnHuqx0pCj8MEGOimQi5TyLE/view?usp=drive" target="_blanck">
						Звіт про надходження та використання коштів за 2022 рік  
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/14hIu9PDcZr0tSb6P_hAPFLpxVUu0xqfU/view?usp=drivesdk.  " target="_blanck">
						Звіт про фінансові результати за 2022 рік  
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1ICGDxL55_FPyZzMlQrB_EBrJWIXRI16D/view?usp=sharing" target="_blanck">
						Кошторис на 2022 рік
					</a>
				</h4>
			
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1g5fqP5SJRLJUupR3znRFRbGj3_lFxBKJ/view?usp=sharing" target="_blanck">
						Кошторис на 2021 рік (за рахунок освітньої субвенції)
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1mMItQhDo7kdOe1gDaYq5w7mpsMsXp26v/view?usp=sharing" target="_blanck">
						Кошторис на 2021 рік (за рахунок коштів місцевого бюджету)
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/183M91dV47df0vDVRRIJ7EQsp-TLcRIEH/view?usp=sharing" target="_blanck">
						Звіт про надходження та використання коштів за перше півріччя 2021року
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1qJMoSMr2-aJhrwzpKfsITtXQSiXcVEoW/view?usp=sharing" target="_blanck">
						Звіт про надходження та використання коштів за 2021 рік
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/177-KVaZAhKKmjMGWlLwLDDlQjXTKV_nE/view?usp=sharing" target="_blanck">
						Звіт про фінансові результати за 2020 рік
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/18g7kPKEZq-RGc_3sneLrntlNDiB1Ozj_/view?usp=sharing" target="_blanck">
						Звіт про фінансові результати за 2021 рік
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1LgSnpfcKsswVDuZaEtcKzLDJCWiOyb1B/view?usp=share_link" target="_blanck">
						Річний звіт про діяльність ДПТНЗ "Львівське вище професійне училище комп'ютерних технологій та будівництва"
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Reporting;
