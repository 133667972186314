import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Zfpo = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='ЗФПО'>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1hwOemi2JjINIxqZVvt-SRBVKeOQE0yMO/view?usp=sharing" target="_blanck">
                        Стратегія розвитку закладу освіти
					</a>
				</h4>
                <br />
				<h4>				
					<a href="https://drive.google.com/file/d/1p20_XLCXlhZtOY2lO87WPtl07qHg5yAY/view?usp=sharing" target="_blanck">
                        Звіт керівника про реалізацію стратегії ДПТНЗ ЛВПУКТБ у сфері фахової передвищої освіти за 2021-2022 рік.
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1osCMlwyclhq7SqRuuxjns-mgiFUhtYo_/view?usp=drive_link" target="_blanck">
                        Кадрове забезпечення. Автомобільний транспорт
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1K0eeayZ3JtgjeyMuUXPuCUuPotUaOYLQ/view?usp=drive_link" target="_blanck">
                        Кадрове забезпечення. Будівництво та цивільна інженерія
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Zfpo;
