import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Competitions = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="атестація">
<br/>
<h4>
<a href="https://drive.google.com/file/d/1v_GVWz6pcCC90vgcnXGECEaI7l10CT1d/view?usp=drive_link" target="_blanck">Наказ на створення астестаційної комісії 2023_2024</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1yHTGVixvi37QZkQAPBQ3_dkiaWeHPrEn/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">Наказ про результати атестації 2024</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1pLSd21ch3sJRz-d11_7FhU0nD7HdyYuG/edit?usp=drive_link&ouid=115639848947452629089&rtpof=true&sd=true" target="_blanck">Графік проведення атестації 2024</a>
</h4>
<br/>
<h4>
<a href="https://mon.gov.ua/ua/npa/pro-zatverdzhennya-polozhennya-pro-atestaciyu-pedagogichnih-pracivnikiv" target="_blanck">Положення про атестацію педагогічних працівників (Нова редакція)</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1ffJ5CHa_9dz8JJG2-ZAreJgHgVxRrlCC/edit?usp=drive_link" target="_blanck">Чергова атестація_Інформація про педагогічних працівників 2024</a>
</h4>
<br/>
<h4>
<a href="https://docs.google.com/document/d/1SHPHIXoo8o1XihxGThoSP20h-KXzOK-s/edit?usp=drive_link" target="_blanck">Позачергова атестація_Інформація про педагогічних працівників 2024</a>
</h4>
<br/>
<h4>
<a href="https://drive.google.com/file/d/1xy_Y--PoJYJc4qIlNbmtq9QEUv3hpzcz/view?usp=drive_link" target="_blanck">Атестація педагогічних працівників по-новому. Інформаційний порадник</a>
</h4>

			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Competitions;