import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import NavSecond from '../../_commonComponents/NavSecond/NavSecond';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';

const Licensing = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title='ліцензування'>
			<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1asivqEHFPoypA466gW6jrMNkp0PNihjx/view?usp=drive_link" target="_blanck">						
						Кадрове забезпечення на рівні середньої освіти
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1y-UdZpncYV7Na1O8j0g-VUYU9csL031c/view?usp=sharing" target="_blanck">						
						Bисновок що підтверджує безперешкодний доступ до будівель для осіб з інвалідністю та інших маломобільних груп населення
					</a>
				</h4>				
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1l84XLV-OWM47o1khqYcKz5kek1EMR01e/view?usp=sharing" target="_blanck">
						Bисновок щодо доступності для осіб з інвалідністю
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/19iPCrPGAoBE0WnUu-iiIhXUfseAz5G8X/view?usp=drive_link" target="_blanck">
						Iнформація про відсутність ЛВПУ КТБ контролю резидентами іноземних держав
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1u5WO6A9-J51hFAp93AwHVPr2ZKbD8o2l/view?usp=sharing" target="_blanck">
						Письмове зобовязання щодо забезпечення безперешкодного доступу до будівель
					</a>
				</h4>		
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1Cp_8Uunrdvgg-ySlikoRRUz1ShUHv1GC/view?usp=sharing" target="_blanck">
						Перелік професій, за якими здійснюється підготовка в закладі освіти
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1lErCLpbtzTaAJPc24jyW-Tq5oAg1aba0/view?usp=sharing" target="_blanck">
						Відомості про кількісні та якісні показники матеріально-технічного забезпечення освітньої діяльності на рівні професійної (професійно-технічної) освіти
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13beke4bwXyVewGzb08n7ZFKmpmbWsV35/view?usp=sharing" target="_blanck">
						Розмір плати за навчання, підготовку, підвищення кваліфікації здобувачів освіти
					</a>
				</h4>
				
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1fRBbQPYGk2LIE7SGRjFOJhuxvlWbVI0o/view?usp=drive_link" target="_blanck">
						Перелік додаткових освітніх та інших послуг, їх вартість, порядок надання оплати
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1vdBLYGd-piXg8cPvQ62L-AFnLxAbgkwP/view?usp=drive_link" target="_blanck">
					Додаток 36_Оператор ДКА_Кадрове забезпечення
					</a>
				</h4>			
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1bZUP2VQx2KvKoKal5gGrCxLnRsH3OKt5/view?usp=drive_link" target="_blanck">
					Додаток 37_ Оператор ДКА_Відомості про кількісні та якісні показники матеріально-технічного забезпечення освітньої діяльності
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1SNP1T_5ZjtyxOSnPHJ7ElCBIMMQTfJrU/view?usp=drive_link" target="_blanck">
					Додаток 38_Оператор ДКА_ Відомості про навчально-методичне забезпечення освітньої діяльності
					</a>
				</h4>		
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/13pHtXUJS3HcaxKVinolhVgZX3V2GIAOT/view?usp=drive_link" target="_blanck">
						 Електрогазозварник_додаток 36_Відомості про кількісні та якісні показники кадрового забезпечення
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1sGfYaignRVNm2hPqSju7rSMUUFDj9M-W/view?usp=drive_link" target="_blanck">
						 Електрогазозварник_додаток 37_Відомості про кількісні та якісні показники матеріально-технічного забезпечення
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://drive.google.com/file/d/1dTprHnCYpoO94Zwf6YltLPSTiTW9_6pg/view?usp=sharing" target="_blanck">
						Електрогазозварник_Відомості про інформаційне забезпечення освітньої діяльності
					</a>
				</h4>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Licensing;