import Header from '../../Header/Header';
import Footer from '../../Footer/Footer';
import Partners from '../../_commonComponents/Partners/Partners';
import Pattern from '../../_commonComponents/Pattern/Pattern';
import './MethodicalWork.css';

const MethodicalWork = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="методична робота">
				<h4>Науково-методична тема над якою працює заклад освіти:</h4>

				<h5>«Створення  сучасного освітнього середовища  для формування професійної, соціальної та особистісної компетентностей у здобувачів освіти закладу професійної освіти». Термін реалізації – 2022-2027 н.р. 2022-2023 навчальний рік</h5>
				<p>
					<br />
				<h5>Методична рада</h5>



				<p>Голова ради - Поліщук М.Б., в.о. директора училища</p>

				<p>Заступник голови -  Хиль С.В., заступник директора з НВР</p>

				<p>Члени методичної ради:</p>

				<p>Химко Л.Я., заступник директора з НВР</p>

				<p>Біленький П.М., старший майстер</p>

				<p>Венцель М.Т., старший майстер</p>

				<p>Трущак О.В., методист</p>

				<p>Голови методичних комісій – Гузар О.В., Бабаченко В.І., Левицька Г.Н., Владига О.М., Корзан Т.І., Білецька О.М., Жукевич М.М., Кубай О.С.</p>



				<p>
					Кубай О.С. – відповідальний за організаційне та методичне керівництво навчально-виробничою діяльністю майстрів в/н та викладачів професійно-теоретичної підготовки професій автомобільного напряму.

					Жукевич М.М. – відповідальна за організаційне та методичне керівництво навчально-виробничою діяльністю майстрів в/н та викладачів професійно-теоретичної підготовки професій будівельного напряму.

					Білецька О.М. - відповідальна за організаційне та методичне керівництво навчально-виробничою діяльністю майстрів в/н та викладачів професійно-теоретичної підготовки професій комп’ютерного напряму.
				</p>
				
				<br />

					<ul>
						<li>Предметно-циклові методичні комісії:</li>

						<li> суспільно-гуманітарної підготовки;</li>

						<li> автомобільного транспорту;</li>

						<li> будівництва та цивільної інженерії;</li>

						<li>Предметні методичні комісії:</li>

						<li> викладачів гуманітарної підготовки;</li>

						<li> викладачів історії, правознавства та суспільних дисциплін;</li>

						<li> викладачів природничо-математичної підготовки, економічних дисциплін;</li>

						<li> викладачів «Захисту України» та «Фізичної культури»;</li>

						<li> автомобільного напряму, електрогазозварників;</li>

						<li> будівельного напряму;</li>

						<li> комп’ютерного напряму;</li>

						<li> класних керівників, вихователів, практичного психолога та педагога соціального.</li>
					</ul>

					<br />

					<p>
						З метою розроблення та впровадження сучасних навчальних інструментів для організації цифрового та дистанційного навчання працює  творча  група «Модель цифрового навчання». Учасники творчої  групи :
					</p>

					<p>Поліщук М.Б., в.о.директора, загальний координатор  LMS MOODLE</p>

					<p>Трущак О.В., методист</p>

					<p>Білецька О.М., координатор проєкту «SELFIE»</p>

					<p>Венцель М.Т., старший майстер</p>

					<p>Якимів Л.М., технічний координатор LMS MOODLE</p>

					<p>Росіцька О.В., викладач  професій комп’ютерного напряму</p>

					<p>Жукевич М.М., викладач предметів професій будівельного напряму</p>

					<p>Пастернак Н.В., викладач предметів про автомобільного напряму</p>

					<p>Корзан Т.І., викладач фізичної культури</p>
				</p>
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default MethodicalWork;