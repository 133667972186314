import Header from '../Header/Header';
import Footer from '../Footer/Footer';
import Partners from '../_commonComponents/Partners/Partners';
import Pattern from '../_commonComponents/Pattern/Pattern';

const Library = () => {	
	return (
		<article>
			<Header />
			
			<Pattern title="бібліотека">
				<h4>				
					<a href="https://drive.google.com/file/d/1KmtWw2mePRtaSb5yatlc2fjI8Z283TJ-/view?usp=sharing" target="_blanck">
						Положення про бібліотеку ДПТНЗ "Львівське вище професійне училище комп'ютерних технологій та будівництва
					</a>
				</h4>
				<br />
				<h4>				
					<a href="https://lib.imzo.gov.ua/pdruchniki-ta-navchaln-posbniki-dlya-zdobuvachv-profesyno-profesyno-tekhnchno-osvti/
" target="_blanck">
						Підручники та навчальні посібники для здобувачів професійної (професійно-технічної) освіти
					</a>
				</h4>
				<br />
			</Pattern>
			
			<Partners />
			
			<Footer />
		</article>
	)
};

export default Library;